import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {useParams} from "react-router-dom";
import axiosInstance from "../../../../axios";
import {toast} from "react-toastify";

const RPEForm = () => {
    const {t} = useTranslation()
    const {rpe_id} = useParams()
    const [userRPe, setUserRPe] = useState(null)
    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState(false)
    const [newCustomEmoji, setNewCustomEmoji] = useState('')
    useEffect(()=>{
        const getUserRPe = async () => {
            try {
                const response = await axiosInstance.get(`/user/rpe/${rpe_id}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                if (response.data !== null) {
                    setUserRPe(response.data)
                    setNewCustomEmoji(response.data.custom_emoji)
                    setLoading(false)
                }
            } catch (error) {
                console.error(error);
                toast.error(t("Failed to fetrch user's RP emoji trigger."));
            }
        }
        getUserRPe()
    },[])

    const saveCustomEmoji = async () => {
        if(userRPe.custom_emoji !== newCustomEmoji && newCustomEmoji !== ''){
            console.log(newCustomEmoji)
            try {
                const response = await axiosInstance.post(`/user/rpe/custom/change`,
                    {
                        interplay_emoji_trigger_id: userRPe.interplay_emoji_trigger.id,
                        emoji: newCustomEmoji
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                if (response.status === 200) {
                    setUserRPe({ ...userRPe, custom_emoji: newCustomEmoji });
                }
            } catch (error) {
                console.error(error);
                toast.error(t("Failed to update user's RP emoji trigger."));
            }
        }
        setEdit(false)
    }

    const switchUseDefaultEmoji = async () => {
        try {
            const response = await axiosInstance.post(`/user/rpe/custom/default`,
                {
                    interplay_emoji_trigger_id: userRPe.interplay_emoji_trigger.id,
                    use_default: !userRPe.use_default
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            if (response.status === 200) {
                setUserRPe({ ...userRPe, use_default: !userRPe.use_default });
            }
        } catch (error) {
            console.error(error);
            toast.error(t("Failed to update user's RP emoji trigger."));
        }
    }

    return !loading && (
        <div className="container mt-4">
            <div className="card mb-3">
                <div className="card-header">
                    <span>{userRPe.use_default ? userRPe.interplay_emoji_trigger.emoji : userRPe.custom_emoji}</span>
                    <span>{t(userRPe.interplay_emoji_trigger.command_tag + "__command")}</span>
                </div>
                <div className="card-body">
                    <div className="my-3">
                        {edit ?
                            <div className="form-group d-flex align-items-center">
                                <label htmlFor="trigger">{t("Emoji Trigger")}:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="custom_emoji"
                                    value={newCustomEmoji}
                                    onChange={(e) => setNewCustomEmoji(e.target.value)}
                                    maxLength={2}
                                />
                                <button className="btn btn-primary" onClick={saveCustomEmoji}>{t("Save")}</button>
                            </div>
                            :
                            <div>
                                <label htmlFor="trigger">{t("Emoji Trigger")}:</label>
                                <span>{userRPe.custom_emoji || ''}</span>
                                <button className="btn btn-primary" onClick={(e) => setEdit(true)}>{t("Edit")}</button>
                            </div>
                        }

                    </div>
                    <div>
                        <button className="btn btn-primary w-100" onClick={switchUseDefaultEmoji}>{userRPe.use_default ? t("Switch to custom"): t("Switch to default")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RPEForm;
