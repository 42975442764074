import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import axiosInstance from "../../../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";

const RPEComponent = ({index, rpe, user_level, onActivate, onDeactivate}) => {
    const {t} = useTranslation()

    const activateRPE = async (rpe_id) =>{
        try {
            const response = await axiosInstance.post(`/user/rpe/activate`,
                {
                    interplay_emoji_trigger_id: rpe_id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            if (response.data !== null) {
                onActivate(index, response.data)
                toast.success(t("Emoji trigger was activated successfully."))
            }
        } catch (error) {
            console.error(error);
            toast.error(t("Failed to fetrch user's RP emoji trigger."));
        }
    }

    const deactivateRPE = async (rpe_id) =>{
        try {
            const response = await axiosInstance.post(`/user/rpe/deactivate`,
                {
                    interplay_emoji_trigger_id: rpe_id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            if (response.status === 200) {
                onDeactivate(index)
                toast.success(t("Emoji trigger was deactivated successfully."))
            }
        } catch (error) {
            console.error(error);
            toast.error(t("Failed to fetrch user's RP emoji trigger."));
        }
    }

    return (
        <div className="w-100 my-2 card">
            <div className="card-header">
                <span>
                    {rpe.user_pres != null && !rpe.user_pres.use_default ? rpe.user_pres.custom_emoji : rpe.base_pres.emoji}
                </span>
                <span>{t(rpe.base_pres.command_tag + "__command")}</span>
            </div>
            <div className="card-body">
                {rpe.user_pres ?
                    <div>
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-warning" onClick={() => deactivateRPE(rpe.base_pres.id)}>{t("Disable")}</button>
                            <Link to={`edit/${rpe.base_pres.id}`} className="btn btn-primary">{t("Edit")}</Link>
                        </div>
                    </div>
                    :
                    <div>
                        {
                            user_level < rpe.base_pres.required_user_level ?
                                <span>{t("Required user level")}: {rpe.base_pres.required_user_level}</span>
                                :
                                <button className="btn btn-primary" onClick={()=> activateRPE(rpe.base_pres.id)}>{t("Activate")}</button>
                        }
                    </div>
                }
            </div>
            {rpe.user_pres != null && !rpe.user_pres.use_default &&
                <div className="card-footer">
                    <small className="hint-text">{t("This trigger is active only in chats where you hold VIP or Premium status.")}</small>
                </div>
            }

        </div>
    );
};

export default RPEComponent;
