import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


function CommandsAccesses() {
    const { chatId } = useParams();
    const [responseData, setResponseData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const getCommandsAccesses = async () => {
            try {
                const response = await axiosInstance.get(`/chatSettings/chatCommandsAccesses/${chatId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setResponseData(response.data);
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch commands settings.")+ JSON.stringify(error.response.data));
            }
        };

        getCommandsAccesses();
    }, [chatId, t]);

    const handleUpdate = (element, level) => {
        const updateCommandAccess = async () => {
            try {
                const response = await axiosInstance.post(`/chatSettings/chatCommandsAccesses/`, {
                    chat_id: Number(chatId),
                    command_tag: element.command_tag,
                    user_group_level_id: level,
                    turn_on: element.turn_on,
                }, {
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.status === 200) {
                    toast.success(t("Command settings updated successfully."));
                } else {
                    toast.error(response.data);
                }

                // Update the visual state after a successful API call
                setResponseData(prevData => prevData.map(item =>
                    item.command_tag === element.command_tag
                        ? { ...item, user_group_level_id: level }
                        : item
                ));
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to update commands settings.")+ JSON.stringify(error.response.data));
            }
        };
        updateCommandAccess();
    };

    const toggleCommandTurOn = async (index, element, turn_on) =>{
        setResponseData(prevData => prevData.map((item,i) =>
            item.command_tag === element.command_tag
                ? { ...item, turn_on: turn_on }
                : item
        ));
        try {
            const response = await axiosInstance.post(`/chatSettings/chatCommandsAccesses/`, {
                chat_id: Number(chatId),
                command_tag: element.command_tag,
                user_group_level_id: element.user_group_level_id,
                turn_on: turn_on,
            }, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (response.status === 200) {
                toast.success(t("Command settings updated successfully."));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            console.log(error);
            toast.error(t("Failed to update commands settings.")+ JSON.stringify(error.response.data));
            setResponseData(prevData => prevData.map(item =>
                item.command_tag === element.command_tag
                    ? { ...item, turn_on: !turn_on }
                    : item
            ));
        }
    }

    const getLocalizedText = (commandTag) => {
        return t(commandTag + '__command');
    };

    const filteredData = responseData.filter(element =>
        getLocalizedText(element.command_tag).toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="container mt-4">
            <h1 className="mb-4">{t("Commands Accesses")}</h1>
            <div className="mb-3">
                <input
                    type="text"
                    placeholder={t("Search")}
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    className="form-control"
                />
            </div>

            <div className="card">
                <div className="card-body">
                    {filteredData.map((element, index) => (
                        <div key={index} className="my-3">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <span className="me-3">{getLocalizedText(element.command_tag)}</span>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="greetingsToggle"
                                        checked={element.turn_on || false}
                                        onChange={(e)=> toggleCommandTurOn(index, element, e.target.checked)}
                                    />
                                </div>
                            </div>

                            <div className="btn-group w-100">
                                {[...Array(6)].map((_, i) => (
                                    <button
                                        type="button"
                                        key={i}
                                        className={`btn ${element.user_group_level_id === i ? 'btn-primary' : 'btn-outline-primary'}`}
                                        onClick={() => handleUpdate(element, i)}
                                    >
                                        {i}
                                    </button>
                                ))}
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    );
}

export default CommandsAccesses;
