import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../../../axios";
import {toast} from "react-toastify";
import RPEComponent from "./RPEComponent";

const RPe = () => {
    const {t} = useTranslation()
    const [interplayRPEs, setInterplayRPEs] = useState([])
    const [userPREs, setUserPREs] = useState([])
    const [userLevel, setUserLevel] = useState(0)

    const [PREs, setPREs] = useState([])

    useEffect(() => {
        const getPREs = async () => {
            try {
                const response = await axiosInstance.get(`/uncategory/rpe`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.data !== null) {
                    setInterplayRPEs(response.data);
                }
            } catch (error) {
                console.error(error);
                toast.error(t("Error to fetch RP emoji triggers."));
            }
        }

        const getUserPREs = async () => {
            try {
                const response = await axiosInstance.get(`/user/rpe`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.data !== null) {
                    setUserPREs(response.data);
                }
            } catch (error) {
                console.error(error);
                toast.error(t("Error to fetch RP emoji triggers."));
            }
        }

        const getUserLevel = async () => {
            try{
                const response = await axiosInstance.get(`/user/info`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.data !== null){
                    setUserLevel(response.data.level)
                }
            }catch (error) {
                console.error(error);
                toast.error(t("Failed to fetch user info."));
            }
        }
        getUserLevel()
        getPREs()
        getUserPREs()
    },[])

    useEffect(() => {
        const combinedPREs = interplayRPEs.map((base) => {
            const matchingUsers = userPREs.find(
                (user) => user.interplay_emoji_trigger.id === base.id
            );
            return {
                user_pres: matchingUsers,
                base_pres: base,
            };
        });
        console.log(combinedPREs)
        setPREs(combinedPREs);
    },[interplayRPEs, userPREs])

    const onActivate = async (index, rpe) => {
        const newPres = PREs.map((e,i) => {
            if(i === index){
                return {
                    user_pres: rpe,
                    base_pres: e.base_pres,
                };
            }else{
                return e
            }
        })
        setPREs(newPres)
    }

    const onDeactivate = async (index) =>{
        const newPres = PREs.map((e,i) => {
            if(i === index){
                return {
                    user_pres: null,
                    base_pres: e.base_pres,
                };
            }else{
                return e
            }
        })
        setPREs(newPres)
    }


    return (
        <div className="w-100 m-0 d-flex flex-column">
            <div className="p-2 flex-grow-1 overflow-auto">
                {PREs.map((e, index) => (
                    <RPEComponent
                        index={index}
                        rpe={e}
                        user_level={userLevel}
                        onActivate={onActivate}
                        onDeactivate={onDeactivate}
                    />
                ))}
            </div>
        </div>
    );
};

export default RPe;
